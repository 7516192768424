export const visibleColumnsByRptType = {
  'book-mgmt-non-pay-policy': [
    'Company',
    'AgentNumber',
    'PolicyNumber',
    'LastName',
    'Name1',
    'Name2',
    'Phone',
    'Addr1',
    'Addr2',
    'Addr_City',
    'Addr_State',
    'Addr_ZIP',
    'AccountNumber',
    'PolicyTypeDescr',
    'LinesInAccount',
    'WrittenPremium',
    'PolicyEffectiveDate',
    'PolicyExpirationDate',
    'CancelDate',
    'MinimumDue',
    'PayMethodDescr',
  ],
  'book-mgmt-cancelled-policy': [
    'Company',
    'AgentNumber',
    'PolicyNumber',
    'LastName',
    'Name1',
    'Name2',
    'Phone',
    'Addr1',
    'Addr2',
    'Addr_City',
    'Addr_State',
    'Addr_ZIP',
    'AccountNumber',
    'PolicyTypeDescr',
    'LinesInAccount',
    'WrittenPremium',
    'PolicyEffectiveDate',
    'PolicyExpirationDate',
    'CancelDate',
    'CancReasonDescr',
    'MinimumDue',
    'PayMethodDescr',
  ],
  'book-mgmt-expiring-policy': [
    'Company',
    'AgentNumber',
    'PolicyNumber',
    'LastName',
    'Name1',
    'Name2',
    'Phone',
    'Addr1',
    'Addr2',
    'Addr_City',
    'Addr_State',
    'Addr_ZIP',
    'AccountNumber',
    'PolicyTypeDescr',
    'LinesInAccount',
    'RenewalPremium',
    'WrittenPremium',
    'PremiumChange',
    'PolicyEffectiveDate',
    'PolicyExpirationDate',
    'CancReasonDescr',
    'MinimumDue',
    'PayMethodDescr',
    'RenewedIndicator',
  ],
  'book-mgmt-installment-due': [
    'AgentNumber',
    'LastName',
    'Name1',
    'Name2',
    'Phone',
    'Addr1',
    'Addr2',
    'Addr_City',
    'Addr_State',
    'Addr_ZIP',
    'AccountNumber',
    'LinesInAccount',
    'WrittenPremium',
    'MinimumDue',
    'PayMethodDescr',
  ],
  'book-mgmt-payment-method': [
    'AgentNumber',
    'LastName',
    'Name1',
    'Name2',
    'Phone',
    'Addr1',
    'Addr2',
    'Addr_City',
    'Addr_State',
    'Addr_ZIP',
    'AccountNumber',
    'LinesInAccount',
    'WrittenPremium',
    'MinimumDue',
    'PayMethodDescr',
  ],
  'book-mgmt-policy-status': ['ProcessDate', 'AgentNumber', 'PolicyNumber', 'Name1', 'PolicyEffectiveDate', 'BusinessInd', 'OldTier', 'NewTier'],
};

export const getGridColumnsByReportType = (reportType, reportColumns, visibleColumns) => {
  const columns = visibleColumns[reportType]; // Get the fields for the given reportType
  if (!columns) {
    return []; // Return empty array if reportType is not found
  }
  return columns.map((field) => reportColumns.find((detail) => detail.field === field)).filter(Boolean);
};
